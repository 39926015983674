import React, {useEffect, useState} from 'react';
import Button from '../components/Button';
import CustomerCard from '../components/CustomerCard';
import Layout from '../components/layout/Layout';
import {products} from '../data/customer-data';

import style from '../css/product.css'


const Index = ({location, data}) => {

    const [product, setProduct] = useState({})

    useEffect(()=>{
        if(typeof location.state?.id !== 'undefined') {
            const id = location.state.id;
            products.forEach(c => {
                c.products.forEach( p => {
                    if(p.id === id) {
                        setProduct(p)
                    }
                })
        })}
    }, []);

  return (
  <Layout>
    <section id="stats" className="py-20">
    <div className='container mx-auto '>
        <div className='text text-4xl font-semibold ml-8'>{product?.name}</div>
        <div className='text text-2xl font-semibold ml-8'>{product?.short}</div>
    </div>
    </section>
    <section id="features" className="container mx-auto py-20 lg:pb-40 lg:pt-8">
        <h3 className="text-3xl font-semibold">Prednosti</h3>
        <div className='flex flex-col md:flex-row pt-8'>
            <div className="container pr-4 pl-12">
                <ul style={{listStyleType: 'disc', color: '#707070'}}>
                {product?.bulletpoints?.map(bullet => {
                    return <li>
                        <p className="text-md pb-2 font-light text-gray-600">
                            {bullet}
                        </p>
                    </li>
                })}
                </ul>
            </div>
            <div className='image-container mb-8 w-full mt-8 md:mt-0'>
                <img src={product.image}></img>
            </div>
        </div>
        <h3 className="text-3xl font-semibold mt-8">Opis</h3>
        <div className='flex flex-col md:flex-row pt-8 '>
            <div className="container pr-4">
                {product?.description?.map(paragraph => {
                    return <p className="text-md pb-6 font-light text-gray-600">
                        {paragraph}
                    </p>
                })}
            </div>
        </div>
      <div className='catalogues'>
        <h3 className="text-3xl font-semibold">Tehnička specifikacija</h3>
        <div className='w-full mt-4 text-md'>
            <table className='product-specification'>
                <tbody>
                    {product?.table?.map(row => {
                        console.log(row)
                        console.log(row[0])
                            return row[0] === 'NAZIV' ? 
                                <tr>
                                    {row.map(data => {
                                            return <td className='heading font-semibold'>{data}</td>
                                        })}
                                </tr> : <tr>
                                    {row.map(data => {
                                            return <td>{data} </td>
                                        })}
                                </tr>
                        })}
                </tbody>
            </table>
        </div>
      </div>
        <p className="mt-12 w-full flex flex-row justify-center">
            <a className="px-4" href="/kontakt">
                <Button size="md">Kontaktirajte nas</Button>
            </a>
        </p>
    </section>
  </Layout>)
}

export default Index;
